@media only screen and (max-width: 1440px) and (min-width: 1200px) {
    #box-menu .item-info-map {
        padding-right: 0px;
    }
}

ul.box-list-project {
    .expand-down {
        background: url(../../../images/expand-more-neg.svg) no-repeat;
        background-size: 30px 30px;
        width: 30px;
        height: 30px;
        transform: rotate(90deg);
        background-position: -2px;

        i {
            display: none;
        }
    }

    .expand-up {
        background: url(../../../images/expand-more-neg.svg) no-repeat;
        background-size: 30px 30px;
        width: 30px;
        height: 30px;
        transform: rotate(-90deg);
        background-position: 2px;

        i {
            display: none;
        }
    }
}

.observatoire-page .list-social {
    .item-social.item-mail a {
        border: none;
        background: url(../../../images/send.svg);
        background-size: 20px 20px;
        width: 20px;
        height: 20px;
        display: inline-block;

        i {
            display: none;
        }
    }

    .item-social.item-print a {
        border: none;
        background: url(../../../images/print.svg);
        background-size: 20px 20px;
        width: 20px;
        height: 20px;
        display: inline-block;

        i {
            display: none;
        }
    }



    .item-social.item-share a {
        border: none;
        background: url(../../../images/share.svg);
        background-size: 20px 20px;
        width: 20px;
        height: 20px;
        display: inline-block;

        i {
            display: none;
        }
    }
}

.observatoire-page.sub-page .border-dotted.separateur-svg {
    overflow: hidden;
    width: 100%;
    border: none;
    margin-bottom: 0;
    margin-top: 15px;

    img {
        height: 3px;
        width: 2200px !important;
        max-width: none;
    }
}

.observatoire-page .box-news-related {
    border: none;
    padding-bottom: 0;

    .separateur-svg {
        padding-top: 30px;
    }
}

// .observatoire-page .box-news-related .box-item-news {
//     bottom: -30px;
// }

ul.box-list-project .box-project .view-more-project .full-text {
    border: none;
}

.box-head .separateur-svg.border-white {
    margin: 0;
    padding: 0;
    position: relative;
    top: 0px;

    img {
        margin-left: 2px;
    }
}

#box-menu .item-socials .box-social .item {
    border: none;
    padding: 0;

    .separateur-svg.border-white {
        padding-top: 20px;
        margin-bottom: 20px;
    }

    &:last-child {
        padding-bottom: 15px;

        .separateur-svg.border-white {
            display: none;
        }
    }
}

#box-menu .item-info-map .excerpt {
    padding-bottom: 0;
    border-bottom: none;
    margin-bottom: 0;

    .separateur-svg.border-white {
        padding-top: 20px;
        margin-bottom: 20px;
    }
}

#box-menu .item-note .date {
    border-bottom: none;
    padding: 0;

    .separateur-svg.border-white {
        padding-top: 5px;
        margin-bottom: 5px;
    }
}

.search-page {
    border: none;
    margin-bottom: 0;
}

.search-page .border-bottom {
    padding-bottom: 0;
    border-bottom: none;
    margin-bottom: 0;

    .separateur-svg {
        padding-top: 20px;
        margin-bottom: 30px;
    }
}

.box-news .box-view-more {
    bottom: 45px;
}

#box-menu #datepicker {
    .ui-datepicker-calendar thead {
        border: navajowhite;
        padding: 0;
    }

    .ui-widget.ui-widget-content {
        position: relative;

        .separateur-svg.border-white {
            padding: 0;
            margin: 0;
        }

        .separateur-svg.border-white.second {
           /* width: calc(100% - 20px);*/
            position: absolute;
            top: 75px;
            left: 0;
        }
    }
}

.agenda-page .block-search .icon-calendar {
    background: url(../../../images/calendar.svg);
    width: 30px;
    height: 30px;
}

.observatoire-page #block-search {
    padding-bottom: 0;
    margin-bottom: 0;
    border: none;

    > .separateur-svg {
        padding-top: 50px;
        margin-bottom: 50px;
    }
}

.observatoire-page .block-search .box-input-info {
    position: relative;
    border: none;

    .separateur-svg {
        width: auto;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        padding-top: 0;

        img {
            width: 4px !important;
            height: 2200px !important;
            margin-top: -5px;
        }
    }
}

.agenda-page .box-search .btn-search-form {
    padding-right: 30px;
    /*background: #000 url(../../../images/expand-more-neg.svg) no-repeat;*/
    background-size: 15px 15px;
    background-position: 94% 10px;
	border: 0px;
	
    i {
        display: none;
    }
    
}

.abecedaire-page {
    .box-filter-anphabe {
        border: none;
        margin-bottom: 0;
    }

    .box-result-filter {
        border-bottom: none;
        padding-bottom: 0;
        margin-bottom: 0;

        .separateur-svg {
            padding-top: 20px;
            margin-bottom: 20px;
        }
    }
}

.abecedaire-page .input-search .btn-search-letter {
    background: url(../../../images/loupe.svg) no-repeat;
    background-size: 20px 20px;
    width: 30px;
    height: 30px;
    background-position: 3px 5px;

    i {
        display: none;
    }
}

.search-page .input-search .btn-search-letter {
    background: url(../../../images/loupe.svg) no-repeat;
    background-size: 15px 15px;
    background-position: 5px 5px;
    width: 30px;
    height: 30px;

    i {
        display: none;
    }
}

.observatoire-page .block-search {
    padding-bottom: 0;
    margin-bottom: 0;
    border: none;

    form .box-search > .separateur-svg {
        padding-top: 50px;
        margin-bottom: 50px;
    }
}

.observatoire-page .box-search .btn-search-form .icon-arrow-right {
    background: url(../../../images/expand-more-neg.svg) no-repeat;
}

.search-page .btn-faq {
    background: #000 url(../../../images/expand-more-neg.svg) no-repeat;
    background-size: 12px 12px;
    background-position: 99%;

    i {
        display: none;
    }
}
