@media print {
    body {
        -webkit-print-color-adjust: exact !important;
    }

    .sf-display-none {
        display: none !important;
    }

    .border-dotted.separateur-svg,
    .breadcrumb-bar,
    .item-mail,
    .separateur-svg,
    .box-view-more,
    .box-news.result-search,
    .title-black.set-ton-color {
        display: none !important;
    }

    a[href]:after {
        content: none !important;
    }

    .title {
        font-size: 15px !important;
    }
}
