// ====================
// Block list: block-system, block-block, block-views, block-[name of modules], ....
// ---
.box-magazines .box-view-more .view-more-news,
.observatoire-page .box-news-related .box-item-news .add-item-news {
    border: none;
    background: url(../../../images/contenus.svg);
    background-size: 30px 30px;

    i {
        display: none;
    }
}


.box-menu-right .btn-search {
    background: url(../../../images/loupe.svg);
    background-size: 30px 30px;

    i {
        display: none;
    }
}


body #box-menu .box-menu-mobile .menu-btn-search {
    background: url(../../../images/loupe-neg.svg);
    background-size: 30px 30px;

    i {
        display: none;
    }
}

#box-menu .item-search .btn {
    background: url(../../../images/loupe-neg.svg) no-repeat;
    background-size: 25px 25px;
    width: 40px;
    height: 40px;
    background-position: 5px 10px;

    i {
        display: none;
    }
}

.box-menu-right .btn-about-us {
    background: url(../../../images/follow.svg);
    background-size: 100px 30px;
    border: none;
    width: 100px;
    font-size: 0;
    transition: all 0.3s ease-in-out;

    &:hover {
        zoom: 1.2;
    }
}

.box-menu-right {
    .btn-information {
        background: url(../../../images/infos.svg);
        background-size: 30px 30px;

        i {
            display: none;
        }
    }
}

#box-menu .box-menu-mobile {
    .menu-btn-information {
        background: url(../../../images/infos-neg.svg);
        background-size: 30px 30px;
        border: none;

        i {
            display: none;
        }
    }
}

.box-menu-right .btn-calendar {
    background: url(../../../images/agenda.svg);
    background-size: 30px 30px;

    i {
        display: none;
    }
}

body #box-menu .box-menu-mobile .menu-btn-calendar {
    background: url(../../../images/agenda-neg.svg);
    background-size: 30px 30px;

    i {
        display: none;
    }
}

.box-menu-right .btn-menu {
    background: url(../../../images/burger.svg);
    background-size: 30px 30px;
    height: 30px;

    i {
        display: none;
    }
}

.box-menu-right .btn-close-menu.active {
    background: url(../../../images/burger-quit.svg);
    background-size: 20px 20px;
    width: 20px;
    height: 20px;
    margin-top: 5px;

    i {
        display: none;
    }
}

#box-menu .list-menu li.has-child span {
    background: url(../../../images/round-add-button-Menu.svg);
    background-size: 12px 12px;
    margin-top: 2px;
    width: 12px;
    height: 12px;
    transition: all 0.3s ease-in-out;

    &[aria-expanded='true'] {
        transform: rotate(50deg);
        transition: all 0.3s ease-in-out;
    }

    i {
        display: none;
    }
}

.separateur-svg {
    overflow: hidden;
    padding-top: 10px;
    margin-bottom: 10px;
    width: 100%;
}

.separateur-svg img {
    height: 3px;
    width: 2200px !important;
    max-width: none;
}

.box-magazines .excerpts {
    padding-bottom: 0;
    margin-bottom: 0;
}

.box-magazines .border-dot-bottom {
    border: 0;
}

.box-magazines.magazines-2 .col-lg-3:first-child .separateur-svg {
    padding-top: 0;
}

.box-magazines .item-house {
    padding-bottom: 0;
    margin-bottom: 0;
    border: none;
    margin-top: 0;

    &:first-child {
        margin-top: 10px;
    }
}

.border-magazine.dot-between {
    margin-top: 10px;
    margin-bottom: 10px;
}

.box-magazines .list-magazine li a {
    padding: 0;
    border: none;
}

.box-head {
    padding-bottom: 0;
    margin-bottom: 0;
    border: none;

    .separateur-svg {
        margin-top: 15px;
        margin-bottom: 15px;
        display: inline-block;
    }
}

.box-news {
    padding-bottom: 0;
    margin-bottom: 0;
    border: none;

    .separateur-svg {
        margin-top: 15px;
        margin-bottom: 50px;
        display: inline-block;
    }
}

#article-meme-sujet{
	margin-top: -30px;
}
#article-manifestation-avenir{
	margin-top: 5px;
}
.box-magazines.mg-default {
    padding-bottom: 0;
    margin-bottom: 0;
    border: none;

    > .separateur-svg {
        margin-top: 50px;
        margin-bottom: 50px;
        display: inline-block;
    }
}

.box-magazines .item-magazine {
    border: none;
}

.box-magazines .col-lg-3 {
    position: relative;

   /* &:nth-child(4) {
        > .separateur-svg {
            display: none;
        }
    }*/
    

    > .separateur-svg {
        width: auto;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        padding-top: 0;

        img {
            width: 4px !important;
            height: 2200px !important;
            margin-top: -5px;
        }
    }
}

#box-menu .menu {
    padding-right: 20px;
}

.observatoire-page.sub-page .box-title-map {
    padding-bottom: 0;
    margin-bottom: 0;
    border-bottom: none;

    .separateur-svg {
        padding-top: 15px;
        margin-bottom: 15px;
    }
}
