.box-magazines.magazines-2 {
    flex-wrap: wrap;
}
.mobile-only {
    display: none !important;

    img {
        height: 3px !important;
        max-width: none !important;
    }
}

.mobile-only.separateur-svg {
    margin-top: 10px !important;
    margin-bottom: 30px !important;
}

.mobile-load-more {
    background: #000 url(../../../images/ico-plus-white.png) no-repeat;
    background-position: center;
    height: 40px;
    width: 100%;
    margin-top: 20px;
    display: none;
}

.box-magazines.mg-default .item-magazine {
    margin-bottom: 30px; 
}

@media only screen and (max-width: 1440px) and (min-width: 1200px) {
    #box-menu.active {
        right: calc(-30% + 75px);
    }
}

@media screen and (max-width: 1199px) {
    .box-magazines.magazines-2 {
        &:before, &::after {
            display: none;
        }
        justify-content: flex-start;
        .col-lg-3{
            flex: 0 0 50%;
            max-width: 50%;
        }
    }
    #box-menu.active {
        left: 95%;
    }

    .box-head .col-lg-9 {
        width: 100%;
    }

    .box-head .col-lg-3 {
        padding-left: 0;
    }
    .box-magazines .col-lg-3:nth-child(2) >.separateur-svg {
        display: none;
    }
    .box-magazines {
        .col-lg-3:nth-child(1),
        .col-lg-3:nth-child(2) {
            position: relative;
            margin-bottom: 100px;
            &::after {
                content: '........................................................'; 
                padding-top: 10px;
                // background: url(../../../images/separateur.svg);
                height: 1px;
                font-size: 13px;
                width: 100%;
                max-width: 100%;
                background-size: 3px;
                display: block;
                // border-bottom: 1px dotted;
                zoom: 1.5;
                letter-spacing: 1px;
                text-align: center;
                position: absolute;
                bottom: -15px;
            }
        }
    }
    // .box-magazines.mg-default {
    //     .col-lg-3:nth-child(1),
    //     .col-lg-3:nth-child(2) {
    //         margin-bottom: 50px;
    //     }
    // }
}
@media (max-width: 1199px) and (min-width: 1025px){
    .box-head{
        display: flex;
        flex-wrap: wrap;
    }
    ul.box-list-project li{
        margin-bottom: rem(5px);
    }
    .box-head .col-lg-9{
        @include flex-width-col-xs(75%);
    }
    .box-head .col-lg-3{
        @include flex-width-col-xs(25%);
    }
    .box-head .separateur-svg{
        @include flex-width-col-xs(100%);
    }
    ul.box-list-project li:last-child{
        margin-top: rem(5px);
        height: 42px;
    }
    ul.box-list-project .box-project .item-project{
        height: 137px;
    }
    ul.box-list-project .box-project, 
    ul.box-list-project .box-project-missing{
        height: 137px;
    }
    ul.list-images-project li.active img{
        height: 610px;
        object-fit: cover;
    }
}

//1024
@media (max-width: 1024px) and (min-width: 481px) {
    ul.box-list-project li:first-child {
        position: absolute;
        bottom: 0;
        width: 49%;
        height: 40px;
        padding-top: 6px;

        .expand-up {
            display: inline-block !important;
            transform: rotate(180deg);
        }
    }

    ul.box-list-project li:last-child {
        width: 49%;
        float: right;
        height: 40px;
        padding-top: 6px;

        .expand-down {
            transform: rotate(0deg);
        }
    }

    ul.box-list-project li {
        .box-project {
            display: none;

            &.active {
                display: block;
            }
        }

        .item-project {
            width: 50%;
            left: 0;
        }

        .view-more-project {
            width: 50%;
            float: right;
        }
    }

    ul.box-list-project .box-project.active .item-project {
        width: 50%;
    }
}

// 991
@media (max-width: $tablet-ht - 1) {
    ul.box-list-project .expand-down {
        background-position: 7px;
    }

    .observatoire-page.sub-page .box-title-map {
        padding-top: 0;
        margin-top: 0;
        border: none;
    }
}

// Medium devices (tablets, 768px and up)
// @media (min-width: 768px){
//     .col-sm-6 {
//         width: 50%;
//     }
// }
@media (max-width: 768px) {
    #box-menu .box-menu-mobile {
        border-bottom: none;

        li {
            padding-right: 20px;
        }

        ~ .separateur-svg.mobile-only {
            display: inline-block !important;
            margin-top: 0 !important;
            padding-top: 0;
        }
    }

    body .container.active {
        right: 45%;
    }
    .box-magazines.magazines-2 {
        margin-bottom: 20px;
    }
}

@media (max-width: 767px) {
    .box-magazines .col-lg-3:nth-child(1),
    .box-magazines .col-lg-3:nth-child(2) {
        margin-bottom: 60px;
        &::after {
            content: '..............................................................................................';
            padding-top: 0; 
        }
    }
    .box-magazines.magazines-2 .col-lg-3:nth-child(1),
    .box-magazines.magazines-2 .col-lg-3:nth-child(2) {
        &::after {
            content: '..............................................'; 
        }
    }
    .box-magazines.magazines-2 .col-lg-3 {
        flex: 0 0 50%;
        max-width: 50%; 
    }
    .box-magazines.magazines-2 .col-lg-3:nth-child(1),
    .box-magazines.magazines-2 .col-lg-3:nth-child(3) {
        >.separateur-svg {
            display: block;
        }
    }
    .observatoire-page .block-search .box-input-info .separateur-svg {
        display: none;
    }

    ul.box-list-project li:first-child .expand-up {
        display: inline-block !important;
    }

    ul.box-list-project .expand-up {
        background-position: -3px;
    }

    ul.box-list-project .expand-down {
        background-position: 3px;
    }
    .box-magazines .col-lg-3 >.separateur-svg {
        display: none;
    }
}

@media (max-width: 640px) {
    .box-magazines .col-lg-3:nth-child(1)::after, .box-magazines .col-lg-3:nth-child(2)::after {
        content: '......................................................................';
    }

    .box-magazines.magazines-2 .col-lg-3:nth-child(1),
    .box-magazines.magazines-2 .col-lg-3:nth-child(2) {
        &::after {
            content: '..............................'; 
        }
    }
}

// 480
@media (max-width: $mobile-md) {
    .box-magazines .col-lg-3:nth-child(1), .box-magazines .col-lg-3:nth-child(2) {
        margin-bottom: 0;
        &:after {
            display: none;
        }
    }
    .row.box-magazines >.separateur-svg {
        display: none;
    }

    ul.box-list-project li ~ li {
        margin-top: 20px;
    }

    .mobile-only {
        display: block !important;
    }

    .box-news .item-news {
        border-bottom: none;
        margin-bottom: 0;
        padding-bottom: 0;
    }

    .box-news,
    .box-magazines {
        .separateur-svg {
            display: none;
        }
    }

    .box-magazines .no-padding .item-magazine {
        border-bottom: none !important;
        padding-bottom: 0;
        margin-bottom: 0;
    }

    .item-magazine .separateur-svg.mobile-only {
        margin-top: 30px !important;
        margin-bottom: 40px !important;
    }

    // .magazines-2 .col-lg-3:first-child .item-magazine {
    //     padding-bottom: 40px;
    //     margin-bottom: 40px;

    //     .separateur-svg.mobile-only {
    //         display: none !important; 
    //     }
    // }

    ul.box-list-project .box-project .view-more-project .full-text {
        height: auto;
    }

    #box-menu.active {
        left: 100%;
    }

    body .container.active {
        right: 100%;
    }

    ul.box-list-project .set-bg-project.set-ton-color {
        display: none;
    }

    .mobile-hide {
        display: none;
    }

    .mobile-load-more {
        display: inline-block;
    }
    .row.box-magazines.magazines-2 .col-lg-3:last-child {
        margin-bottom: 40px;
        .separateur-svg.mobile-only{
            display: none!important;
        }
    }
    .box-magazines.magazines-2 .col-lg-3 {
        flex: 0 0 100%;
        max-width: 100%; 
    }
    .box-magazines.magazines-2 .col-lg-3:nth-child(1)>.separateur-svg,
    .box-magazines.magazines-2 .col-lg-3:nth-child(3)>.separateur-svg {
        display: none;
    }
}

// 360
@media (max-width: $mobile-sm) {}

// 320
@media (max-width: $mobile-xs) {

}
