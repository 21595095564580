/*------------------------------------------------------------------
CSS
-------------------------------------------------------------------*/
@-webkit-keyframes fadeInFromNone {
  from {
    display: none;
    opacity: 0; }
  to {
    display: block;
    opacity: 1; } }

@keyframes fadeInFromNone {
  from {
    display: none;
    opacity: 0; }
  to {
    display: block;
    opacity: 1; } }

dl a {
  color: #343434; }
  dl a:hover, dl a:focus {
    color: #2A5CAA; }

dl dt {
  border-top: 1px dashed #a7a7a7;
  padding-top: 10px;
  font-size: 0.875rem;
  font-family: "Open Sans", sans-serif;
  font-weight: 700; }
  dl dt:first-child {
    border-top: none;
    padding-top: 0; }
  dl dt + dt {
    margin-top: 10px; }
  dl dt:empty {
    display: none; }

dl dd {
  font-size: 0.8125rem;
  margin-left: 0;
  padding-bottom: 0.625rem; }
  dl dd:last-child {
    padding-bottom: 0; }

/* Z-Index */
.float-left {
  float: left; }

.float-right {
  float: right; }

.float-none {
  float: none; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left; }
  .float-sm-right {
    float: right; }
  .float-sm-none {
    float: none; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left; }
  .float-md-right {
    float: right; }
  .float-md-none {
    float: none; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left; }
  .float-lg-right {
    float: right; }
  .float-lg-none {
    float: none; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left; }
  .float-xl-right {
    float: right; }
  .float-xl-none {
    float: none; } }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.m-10 {
  margin: 0.625rem !important; }

.mt-10,
.my-10 {
  margin-top: 0.625rem !important; }

.mr-10,
.mx-10 {
  margin-right: 0.625rem !important; }

.mb-10,
.my-10 {
  margin-bottom: 0.625rem !important; }

.ml-10,
.mx-10 {
  margin-left: 0.625rem !important; }

.m-15 {
  margin: 0.9375rem !important; }

.mt-15,
.my-15 {
  margin-top: 0.9375rem !important; }

.mr-15,
.mx-15 {
  margin-right: 0.9375rem !important; }

.mb-15,
.my-15 {
  margin-bottom: 0.9375rem !important; }

.ml-15,
.mx-15 {
  margin-left: 0.9375rem !important; }

.m-20 {
  margin: 1.25rem !important; }

.mt-20,
.my-20 {
  margin-top: 1.25rem !important; }

.mr-20,
.mx-20 {
  margin-right: 1.25rem !important; }

.mb-20,
.my-20 {
  margin-bottom: 1.25rem !important; }

.ml-20,
.mx-20 {
  margin-left: 1.25rem !important; }

.m-25 {
  margin: 1.5625rem !important; }

.mt-25,
.my-25 {
  margin-top: 1.5625rem !important; }

.mr-25,
.mx-25 {
  margin-right: 1.5625rem !important; }

.mb-25,
.my-25 {
  margin-bottom: 1.5625rem !important; }

.ml-25,
.mx-25 {
  margin-left: 1.5625rem !important; }

.m-30 {
  margin: 1.875rem !important; }

.mt-30,
.my-30 {
  margin-top: 1.875rem !important; }

.mr-30,
.mx-30 {
  margin-right: 1.875rem !important; }

.mb-30,
.my-30 {
  margin-bottom: 1.875rem !important; }

.ml-30,
.mx-30 {
  margin-left: 1.875rem !important; }

.m-40 {
  margin: 2.5rem !important; }

.mt-40,
.my-40 {
  margin-top: 2.5rem !important; }

.mr-40,
.mx-40 {
  margin-right: 2.5rem !important; }

.mb-40,
.my-40 {
  margin-bottom: 2.5rem !important; }

.ml-40,
.mx-40 {
  margin-left: 2.5rem !important; }

.m-50 {
  margin: 3.125rem !important; }

.mt-50,
.my-50 {
  margin-top: 3.125rem !important; }

.mr-50,
.mx-50 {
  margin-right: 3.125rem !important; }

.mb-50,
.my-50 {
  margin-bottom: 3.125rem !important; }

.ml-50,
.mx-50 {
  margin-left: 3.125rem !important; }

.m-60 {
  margin: 3.75rem !important; }

.mt-60,
.my-60 {
  margin-top: 3.75rem !important; }

.mr-60,
.mx-60 {
  margin-right: 3.75rem !important; }

.mb-60,
.my-60 {
  margin-bottom: 3.75rem !important; }

.ml-60,
.mx-60 {
  margin-left: 3.75rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.p-10 {
  padding: 0.625rem !important; }

.pt-10,
.py-10 {
  padding-top: 0.625rem !important; }

.pr-10,
.px-10 {
  padding-right: 0.625rem !important; }

.pb-10,
.py-10 {
  padding-bottom: 0.625rem !important; }

.pl-10,
.px-10 {
  padding-left: 0.625rem !important; }

.p-15 {
  padding: 0.9375rem !important; }

.pt-15,
.py-15 {
  padding-top: 0.9375rem !important; }

.pr-15,
.px-15 {
  padding-right: 0.9375rem !important; }

.pb-15,
.py-15 {
  padding-bottom: 0.9375rem !important; }

.pl-15,
.px-15 {
  padding-left: 0.9375rem !important; }

.p-20 {
  padding: 1.25rem !important; }

.pt-20,
.py-20 {
  padding-top: 1.25rem !important; }

.pr-20,
.px-20 {
  padding-right: 1.25rem !important; }

.pb-20,
.py-20 {
  padding-bottom: 1.25rem !important; }

.pl-20,
.px-20 {
  padding-left: 1.25rem !important; }

.p-25 {
  padding: 1.5625rem !important; }

.pt-25,
.py-25 {
  padding-top: 1.5625rem !important; }

.pr-25,
.px-25 {
  padding-right: 1.5625rem !important; }

.pb-25,
.py-25 {
  padding-bottom: 1.5625rem !important; }

.pl-25,
.px-25 {
  padding-left: 1.5625rem !important; }

.p-30 {
  padding: 1.875rem !important; }

.pt-30,
.py-30 {
  padding-top: 1.875rem !important; }

.pr-30,
.px-30 {
  padding-right: 1.875rem !important; }

.pb-30,
.py-30 {
  padding-bottom: 1.875rem !important; }

.pl-30,
.px-30 {
  padding-left: 1.875rem !important; }

.p-40 {
  padding: 2.5rem !important; }

.pt-40,
.py-40 {
  padding-top: 2.5rem !important; }

.pr-40,
.px-40 {
  padding-right: 2.5rem !important; }

.pb-40,
.py-40 {
  padding-bottom: 2.5rem !important; }

.pl-40,
.px-40 {
  padding-left: 2.5rem !important; }

.p-50 {
  padding: 3.125rem !important; }

.pt-50,
.py-50 {
  padding-top: 3.125rem !important; }

.pr-50,
.px-50 {
  padding-right: 3.125rem !important; }

.pb-50,
.py-50 {
  padding-bottom: 3.125rem !important; }

.pl-50,
.px-50 {
  padding-left: 3.125rem !important; }

.p-60 {
  padding: 3.75rem !important; }

.pt-60,
.py-60 {
  padding-top: 3.75rem !important; }

.pr-60,
.px-60 {
  padding-right: 3.75rem !important; }

.pb-60,
.py-60 {
  padding-bottom: 3.75rem !important; }

.pl-60,
.px-60 {
  padding-left: 3.75rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .m-sm-10 {
    margin: 0.625rem !important; }
  .mt-sm-10,
  .my-sm-10 {
    margin-top: 0.625rem !important; }
  .mr-sm-10,
  .mx-sm-10 {
    margin-right: 0.625rem !important; }
  .mb-sm-10,
  .my-sm-10 {
    margin-bottom: 0.625rem !important; }
  .ml-sm-10,
  .mx-sm-10 {
    margin-left: 0.625rem !important; }
  .m-sm-15 {
    margin: 0.9375rem !important; }
  .mt-sm-15,
  .my-sm-15 {
    margin-top: 0.9375rem !important; }
  .mr-sm-15,
  .mx-sm-15 {
    margin-right: 0.9375rem !important; }
  .mb-sm-15,
  .my-sm-15 {
    margin-bottom: 0.9375rem !important; }
  .ml-sm-15,
  .mx-sm-15 {
    margin-left: 0.9375rem !important; }
  .m-sm-20 {
    margin: 1.25rem !important; }
  .mt-sm-20,
  .my-sm-20 {
    margin-top: 1.25rem !important; }
  .mr-sm-20,
  .mx-sm-20 {
    margin-right: 1.25rem !important; }
  .mb-sm-20,
  .my-sm-20 {
    margin-bottom: 1.25rem !important; }
  .ml-sm-20,
  .mx-sm-20 {
    margin-left: 1.25rem !important; }
  .m-sm-25 {
    margin: 1.5625rem !important; }
  .mt-sm-25,
  .my-sm-25 {
    margin-top: 1.5625rem !important; }
  .mr-sm-25,
  .mx-sm-25 {
    margin-right: 1.5625rem !important; }
  .mb-sm-25,
  .my-sm-25 {
    margin-bottom: 1.5625rem !important; }
  .ml-sm-25,
  .mx-sm-25 {
    margin-left: 1.5625rem !important; }
  .m-sm-30 {
    margin: 1.875rem !important; }
  .mt-sm-30,
  .my-sm-30 {
    margin-top: 1.875rem !important; }
  .mr-sm-30,
  .mx-sm-30 {
    margin-right: 1.875rem !important; }
  .mb-sm-30,
  .my-sm-30 {
    margin-bottom: 1.875rem !important; }
  .ml-sm-30,
  .mx-sm-30 {
    margin-left: 1.875rem !important; }
  .m-sm-40 {
    margin: 2.5rem !important; }
  .mt-sm-40,
  .my-sm-40 {
    margin-top: 2.5rem !important; }
  .mr-sm-40,
  .mx-sm-40 {
    margin-right: 2.5rem !important; }
  .mb-sm-40,
  .my-sm-40 {
    margin-bottom: 2.5rem !important; }
  .ml-sm-40,
  .mx-sm-40 {
    margin-left: 2.5rem !important; }
  .m-sm-50 {
    margin: 3.125rem !important; }
  .mt-sm-50,
  .my-sm-50 {
    margin-top: 3.125rem !important; }
  .mr-sm-50,
  .mx-sm-50 {
    margin-right: 3.125rem !important; }
  .mb-sm-50,
  .my-sm-50 {
    margin-bottom: 3.125rem !important; }
  .ml-sm-50,
  .mx-sm-50 {
    margin-left: 3.125rem !important; }
  .m-sm-60 {
    margin: 3.75rem !important; }
  .mt-sm-60,
  .my-sm-60 {
    margin-top: 3.75rem !important; }
  .mr-sm-60,
  .mx-sm-60 {
    margin-right: 3.75rem !important; }
  .mb-sm-60,
  .my-sm-60 {
    margin-bottom: 3.75rem !important; }
  .ml-sm-60,
  .mx-sm-60 {
    margin-left: 3.75rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .p-sm-10 {
    padding: 0.625rem !important; }
  .pt-sm-10,
  .py-sm-10 {
    padding-top: 0.625rem !important; }
  .pr-sm-10,
  .px-sm-10 {
    padding-right: 0.625rem !important; }
  .pb-sm-10,
  .py-sm-10 {
    padding-bottom: 0.625rem !important; }
  .pl-sm-10,
  .px-sm-10 {
    padding-left: 0.625rem !important; }
  .p-sm-15 {
    padding: 0.9375rem !important; }
  .pt-sm-15,
  .py-sm-15 {
    padding-top: 0.9375rem !important; }
  .pr-sm-15,
  .px-sm-15 {
    padding-right: 0.9375rem !important; }
  .pb-sm-15,
  .py-sm-15 {
    padding-bottom: 0.9375rem !important; }
  .pl-sm-15,
  .px-sm-15 {
    padding-left: 0.9375rem !important; }
  .p-sm-20 {
    padding: 1.25rem !important; }
  .pt-sm-20,
  .py-sm-20 {
    padding-top: 1.25rem !important; }
  .pr-sm-20,
  .px-sm-20 {
    padding-right: 1.25rem !important; }
  .pb-sm-20,
  .py-sm-20 {
    padding-bottom: 1.25rem !important; }
  .pl-sm-20,
  .px-sm-20 {
    padding-left: 1.25rem !important; }
  .p-sm-25 {
    padding: 1.5625rem !important; }
  .pt-sm-25,
  .py-sm-25 {
    padding-top: 1.5625rem !important; }
  .pr-sm-25,
  .px-sm-25 {
    padding-right: 1.5625rem !important; }
  .pb-sm-25,
  .py-sm-25 {
    padding-bottom: 1.5625rem !important; }
  .pl-sm-25,
  .px-sm-25 {
    padding-left: 1.5625rem !important; }
  .p-sm-30 {
    padding: 1.875rem !important; }
  .pt-sm-30,
  .py-sm-30 {
    padding-top: 1.875rem !important; }
  .pr-sm-30,
  .px-sm-30 {
    padding-right: 1.875rem !important; }
  .pb-sm-30,
  .py-sm-30 {
    padding-bottom: 1.875rem !important; }
  .pl-sm-30,
  .px-sm-30 {
    padding-left: 1.875rem !important; }
  .p-sm-40 {
    padding: 2.5rem !important; }
  .pt-sm-40,
  .py-sm-40 {
    padding-top: 2.5rem !important; }
  .pr-sm-40,
  .px-sm-40 {
    padding-right: 2.5rem !important; }
  .pb-sm-40,
  .py-sm-40 {
    padding-bottom: 2.5rem !important; }
  .pl-sm-40,
  .px-sm-40 {
    padding-left: 2.5rem !important; }
  .p-sm-50 {
    padding: 3.125rem !important; }
  .pt-sm-50,
  .py-sm-50 {
    padding-top: 3.125rem !important; }
  .pr-sm-50,
  .px-sm-50 {
    padding-right: 3.125rem !important; }
  .pb-sm-50,
  .py-sm-50 {
    padding-bottom: 3.125rem !important; }
  .pl-sm-50,
  .px-sm-50 {
    padding-left: 3.125rem !important; }
  .p-sm-60 {
    padding: 3.75rem !important; }
  .pt-sm-60,
  .py-sm-60 {
    padding-top: 3.75rem !important; }
  .pr-sm-60,
  .px-sm-60 {
    padding-right: 3.75rem !important; }
  .pb-sm-60,
  .py-sm-60 {
    padding-bottom: 3.75rem !important; }
  .pl-sm-60,
  .px-sm-60 {
    padding-left: 3.75rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .m-md-10 {
    margin: 0.625rem !important; }
  .mt-md-10,
  .my-md-10 {
    margin-top: 0.625rem !important; }
  .mr-md-10,
  .mx-md-10 {
    margin-right: 0.625rem !important; }
  .mb-md-10,
  .my-md-10 {
    margin-bottom: 0.625rem !important; }
  .ml-md-10,
  .mx-md-10 {
    margin-left: 0.625rem !important; }
  .m-md-15 {
    margin: 0.9375rem !important; }
  .mt-md-15,
  .my-md-15 {
    margin-top: 0.9375rem !important; }
  .mr-md-15,
  .mx-md-15 {
    margin-right: 0.9375rem !important; }
  .mb-md-15,
  .my-md-15 {
    margin-bottom: 0.9375rem !important; }
  .ml-md-15,
  .mx-md-15 {
    margin-left: 0.9375rem !important; }
  .m-md-20 {
    margin: 1.25rem !important; }
  .mt-md-20,
  .my-md-20 {
    margin-top: 1.25rem !important; }
  .mr-md-20,
  .mx-md-20 {
    margin-right: 1.25rem !important; }
  .mb-md-20,
  .my-md-20 {
    margin-bottom: 1.25rem !important; }
  .ml-md-20,
  .mx-md-20 {
    margin-left: 1.25rem !important; }
  .m-md-25 {
    margin: 1.5625rem !important; }
  .mt-md-25,
  .my-md-25 {
    margin-top: 1.5625rem !important; }
  .mr-md-25,
  .mx-md-25 {
    margin-right: 1.5625rem !important; }
  .mb-md-25,
  .my-md-25 {
    margin-bottom: 1.5625rem !important; }
  .ml-md-25,
  .mx-md-25 {
    margin-left: 1.5625rem !important; }
  .m-md-30 {
    margin: 1.875rem !important; }
  .mt-md-30,
  .my-md-30 {
    margin-top: 1.875rem !important; }
  .mr-md-30,
  .mx-md-30 {
    margin-right: 1.875rem !important; }
  .mb-md-30,
  .my-md-30 {
    margin-bottom: 1.875rem !important; }
  .ml-md-30,
  .mx-md-30 {
    margin-left: 1.875rem !important; }
  .m-md-40 {
    margin: 2.5rem !important; }
  .mt-md-40,
  .my-md-40 {
    margin-top: 2.5rem !important; }
  .mr-md-40,
  .mx-md-40 {
    margin-right: 2.5rem !important; }
  .mb-md-40,
  .my-md-40 {
    margin-bottom: 2.5rem !important; }
  .ml-md-40,
  .mx-md-40 {
    margin-left: 2.5rem !important; }
  .m-md-50 {
    margin: 3.125rem !important; }
  .mt-md-50,
  .my-md-50 {
    margin-top: 3.125rem !important; }
  .mr-md-50,
  .mx-md-50 {
    margin-right: 3.125rem !important; }
  .mb-md-50,
  .my-md-50 {
    margin-bottom: 3.125rem !important; }
  .ml-md-50,
  .mx-md-50 {
    margin-left: 3.125rem !important; }
  .m-md-60 {
    margin: 3.75rem !important; }
  .mt-md-60,
  .my-md-60 {
    margin-top: 3.75rem !important; }
  .mr-md-60,
  .mx-md-60 {
    margin-right: 3.75rem !important; }
  .mb-md-60,
  .my-md-60 {
    margin-bottom: 3.75rem !important; }
  .ml-md-60,
  .mx-md-60 {
    margin-left: 3.75rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .p-md-10 {
    padding: 0.625rem !important; }
  .pt-md-10,
  .py-md-10 {
    padding-top: 0.625rem !important; }
  .pr-md-10,
  .px-md-10 {
    padding-right: 0.625rem !important; }
  .pb-md-10,
  .py-md-10 {
    padding-bottom: 0.625rem !important; }
  .pl-md-10,
  .px-md-10 {
    padding-left: 0.625rem !important; }
  .p-md-15 {
    padding: 0.9375rem !important; }
  .pt-md-15,
  .py-md-15 {
    padding-top: 0.9375rem !important; }
  .pr-md-15,
  .px-md-15 {
    padding-right: 0.9375rem !important; }
  .pb-md-15,
  .py-md-15 {
    padding-bottom: 0.9375rem !important; }
  .pl-md-15,
  .px-md-15 {
    padding-left: 0.9375rem !important; }
  .p-md-20 {
    padding: 1.25rem !important; }
  .pt-md-20,
  .py-md-20 {
    padding-top: 1.25rem !important; }
  .pr-md-20,
  .px-md-20 {
    padding-right: 1.25rem !important; }
  .pb-md-20,
  .py-md-20 {
    padding-bottom: 1.25rem !important; }
  .pl-md-20,
  .px-md-20 {
    padding-left: 1.25rem !important; }
  .p-md-25 {
    padding: 1.5625rem !important; }
  .pt-md-25,
  .py-md-25 {
    padding-top: 1.5625rem !important; }
  .pr-md-25,
  .px-md-25 {
    padding-right: 1.5625rem !important; }
  .pb-md-25,
  .py-md-25 {
    padding-bottom: 1.5625rem !important; }
  .pl-md-25,
  .px-md-25 {
    padding-left: 1.5625rem !important; }
  .p-md-30 {
    padding: 1.875rem !important; }
  .pt-md-30,
  .py-md-30 {
    padding-top: 1.875rem !important; }
  .pr-md-30,
  .px-md-30 {
    padding-right: 1.875rem !important; }
  .pb-md-30,
  .py-md-30 {
    padding-bottom: 1.875rem !important; }
  .pl-md-30,
  .px-md-30 {
    padding-left: 1.875rem !important; }
  .p-md-40 {
    padding: 2.5rem !important; }
  .pt-md-40,
  .py-md-40 {
    padding-top: 2.5rem !important; }
  .pr-md-40,
  .px-md-40 {
    padding-right: 2.5rem !important; }
  .pb-md-40,
  .py-md-40 {
    padding-bottom: 2.5rem !important; }
  .pl-md-40,
  .px-md-40 {
    padding-left: 2.5rem !important; }
  .p-md-50 {
    padding: 3.125rem !important; }
  .pt-md-50,
  .py-md-50 {
    padding-top: 3.125rem !important; }
  .pr-md-50,
  .px-md-50 {
    padding-right: 3.125rem !important; }
  .pb-md-50,
  .py-md-50 {
    padding-bottom: 3.125rem !important; }
  .pl-md-50,
  .px-md-50 {
    padding-left: 3.125rem !important; }
  .p-md-60 {
    padding: 3.75rem !important; }
  .pt-md-60,
  .py-md-60 {
    padding-top: 3.75rem !important; }
  .pr-md-60,
  .px-md-60 {
    padding-right: 3.75rem !important; }
  .pb-md-60,
  .py-md-60 {
    padding-bottom: 3.75rem !important; }
  .pl-md-60,
  .px-md-60 {
    padding-left: 3.75rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .m-lg-10 {
    margin: 0.625rem !important; }
  .mt-lg-10,
  .my-lg-10 {
    margin-top: 0.625rem !important; }
  .mr-lg-10,
  .mx-lg-10 {
    margin-right: 0.625rem !important; }
  .mb-lg-10,
  .my-lg-10 {
    margin-bottom: 0.625rem !important; }
  .ml-lg-10,
  .mx-lg-10 {
    margin-left: 0.625rem !important; }
  .m-lg-15 {
    margin: 0.9375rem !important; }
  .mt-lg-15,
  .my-lg-15 {
    margin-top: 0.9375rem !important; }
  .mr-lg-15,
  .mx-lg-15 {
    margin-right: 0.9375rem !important; }
  .mb-lg-15,
  .my-lg-15 {
    margin-bottom: 0.9375rem !important; }
  .ml-lg-15,
  .mx-lg-15 {
    margin-left: 0.9375rem !important; }
  .m-lg-20 {
    margin: 1.25rem !important; }
  .mt-lg-20,
  .my-lg-20 {
    margin-top: 1.25rem !important; }
  .mr-lg-20,
  .mx-lg-20 {
    margin-right: 1.25rem !important; }
  .mb-lg-20,
  .my-lg-20 {
    margin-bottom: 1.25rem !important; }
  .ml-lg-20,
  .mx-lg-20 {
    margin-left: 1.25rem !important; }
  .m-lg-25 {
    margin: 1.5625rem !important; }
  .mt-lg-25,
  .my-lg-25 {
    margin-top: 1.5625rem !important; }
  .mr-lg-25,
  .mx-lg-25 {
    margin-right: 1.5625rem !important; }
  .mb-lg-25,
  .my-lg-25 {
    margin-bottom: 1.5625rem !important; }
  .ml-lg-25,
  .mx-lg-25 {
    margin-left: 1.5625rem !important; }
  .m-lg-30 {
    margin: 1.875rem !important; }
  .mt-lg-30,
  .my-lg-30 {
    margin-top: 1.875rem !important; }
  .mr-lg-30,
  .mx-lg-30 {
    margin-right: 1.875rem !important; }
  .mb-lg-30,
  .my-lg-30 {
    margin-bottom: 1.875rem !important; }
  .ml-lg-30,
  .mx-lg-30 {
    margin-left: 1.875rem !important; }
  .m-lg-40 {
    margin: 2.5rem !important; }
  .mt-lg-40,
  .my-lg-40 {
    margin-top: 2.5rem !important; }
  .mr-lg-40,
  .mx-lg-40 {
    margin-right: 2.5rem !important; }
  .mb-lg-40,
  .my-lg-40 {
    margin-bottom: 2.5rem !important; }
  .ml-lg-40,
  .mx-lg-40 {
    margin-left: 2.5rem !important; }
  .m-lg-50 {
    margin: 3.125rem !important; }
  .mt-lg-50,
  .my-lg-50 {
    margin-top: 3.125rem !important; }
  .mr-lg-50,
  .mx-lg-50 {
    margin-right: 3.125rem !important; }
  .mb-lg-50,
  .my-lg-50 {
    margin-bottom: 3.125rem !important; }
  .ml-lg-50,
  .mx-lg-50 {
    margin-left: 3.125rem !important; }
  .m-lg-60 {
    margin: 3.75rem !important; }
  .mt-lg-60,
  .my-lg-60 {
    margin-top: 3.75rem !important; }
  .mr-lg-60,
  .mx-lg-60 {
    margin-right: 3.75rem !important; }
  .mb-lg-60,
  .my-lg-60 {
    margin-bottom: 3.75rem !important; }
  .ml-lg-60,
  .mx-lg-60 {
    margin-left: 3.75rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .p-lg-10 {
    padding: 0.625rem !important; }
  .pt-lg-10,
  .py-lg-10 {
    padding-top: 0.625rem !important; }
  .pr-lg-10,
  .px-lg-10 {
    padding-right: 0.625rem !important; }
  .pb-lg-10,
  .py-lg-10 {
    padding-bottom: 0.625rem !important; }
  .pl-lg-10,
  .px-lg-10 {
    padding-left: 0.625rem !important; }
  .p-lg-15 {
    padding: 0.9375rem !important; }
  .pt-lg-15,
  .py-lg-15 {
    padding-top: 0.9375rem !important; }
  .pr-lg-15,
  .px-lg-15 {
    padding-right: 0.9375rem !important; }
  .pb-lg-15,
  .py-lg-15 {
    padding-bottom: 0.9375rem !important; }
  .pl-lg-15,
  .px-lg-15 {
    padding-left: 0.9375rem !important; }
  .p-lg-20 {
    padding: 1.25rem !important; }
  .pt-lg-20,
  .py-lg-20 {
    padding-top: 1.25rem !important; }
  .pr-lg-20,
  .px-lg-20 {
    padding-right: 1.25rem !important; }
  .pb-lg-20,
  .py-lg-20 {
    padding-bottom: 1.25rem !important; }
  .pl-lg-20,
  .px-lg-20 {
    padding-left: 1.25rem !important; }
  .p-lg-25 {
    padding: 1.5625rem !important; }
  .pt-lg-25,
  .py-lg-25 {
    padding-top: 1.5625rem !important; }
  .pr-lg-25,
  .px-lg-25 {
    padding-right: 1.5625rem !important; }
  .pb-lg-25,
  .py-lg-25 {
    padding-bottom: 1.5625rem !important; }
  .pl-lg-25,
  .px-lg-25 {
    padding-left: 1.5625rem !important; }
  .p-lg-30 {
    padding: 1.875rem !important; }
  .pt-lg-30,
  .py-lg-30 {
    padding-top: 1.875rem !important; }
  .pr-lg-30,
  .px-lg-30 {
    padding-right: 1.875rem !important; }
  .pb-lg-30,
  .py-lg-30 {
    padding-bottom: 1.875rem !important; }
  .pl-lg-30,
  .px-lg-30 {
    padding-left: 1.875rem !important; }
  .p-lg-40 {
    padding: 2.5rem !important; }
  .pt-lg-40,
  .py-lg-40 {
    padding-top: 2.5rem !important; }
  .pr-lg-40,
  .px-lg-40 {
    padding-right: 2.5rem !important; }
  .pb-lg-40,
  .py-lg-40 {
    padding-bottom: 2.5rem !important; }
  .pl-lg-40,
  .px-lg-40 {
    padding-left: 2.5rem !important; }
  .p-lg-50 {
    padding: 3.125rem !important; }
  .pt-lg-50,
  .py-lg-50 {
    padding-top: 3.125rem !important; }
  .pr-lg-50,
  .px-lg-50 {
    padding-right: 3.125rem !important; }
  .pb-lg-50,
  .py-lg-50 {
    padding-bottom: 3.125rem !important; }
  .pl-lg-50,
  .px-lg-50 {
    padding-left: 3.125rem !important; }
  .p-lg-60 {
    padding: 3.75rem !important; }
  .pt-lg-60,
  .py-lg-60 {
    padding-top: 3.75rem !important; }
  .pr-lg-60,
  .px-lg-60 {
    padding-right: 3.75rem !important; }
  .pb-lg-60,
  .py-lg-60 {
    padding-bottom: 3.75rem !important; }
  .pl-lg-60,
  .px-lg-60 {
    padding-left: 3.75rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .m-xl-10 {
    margin: 0.625rem !important; }
  .mt-xl-10,
  .my-xl-10 {
    margin-top: 0.625rem !important; }
  .mr-xl-10,
  .mx-xl-10 {
    margin-right: 0.625rem !important; }
  .mb-xl-10,
  .my-xl-10 {
    margin-bottom: 0.625rem !important; }
  .ml-xl-10,
  .mx-xl-10 {
    margin-left: 0.625rem !important; }
  .m-xl-15 {
    margin: 0.9375rem !important; }
  .mt-xl-15,
  .my-xl-15 {
    margin-top: 0.9375rem !important; }
  .mr-xl-15,
  .mx-xl-15 {
    margin-right: 0.9375rem !important; }
  .mb-xl-15,
  .my-xl-15 {
    margin-bottom: 0.9375rem !important; }
  .ml-xl-15,
  .mx-xl-15 {
    margin-left: 0.9375rem !important; }
  .m-xl-20 {
    margin: 1.25rem !important; }
  .mt-xl-20,
  .my-xl-20 {
    margin-top: 1.25rem !important; }
  .mr-xl-20,
  .mx-xl-20 {
    margin-right: 1.25rem !important; }
  .mb-xl-20,
  .my-xl-20 {
    margin-bottom: 1.25rem !important; }
  .ml-xl-20,
  .mx-xl-20 {
    margin-left: 1.25rem !important; }
  .m-xl-25 {
    margin: 1.5625rem !important; }
  .mt-xl-25,
  .my-xl-25 {
    margin-top: 1.5625rem !important; }
  .mr-xl-25,
  .mx-xl-25 {
    margin-right: 1.5625rem !important; }
  .mb-xl-25,
  .my-xl-25 {
    margin-bottom: 1.5625rem !important; }
  .ml-xl-25,
  .mx-xl-25 {
    margin-left: 1.5625rem !important; }
  .m-xl-30 {
    margin: 1.875rem !important; }
  .mt-xl-30,
  .my-xl-30 {
    margin-top: 1.875rem !important; }
  .mr-xl-30,
  .mx-xl-30 {
    margin-right: 1.875rem !important; }
  .mb-xl-30,
  .my-xl-30 {
    margin-bottom: 1.875rem !important; }
  .ml-xl-30,
  .mx-xl-30 {
    margin-left: 1.875rem !important; }
  .m-xl-40 {
    margin: 2.5rem !important; }
  .mt-xl-40,
  .my-xl-40 {
    margin-top: 2.5rem !important; }
  .mr-xl-40,
  .mx-xl-40 {
    margin-right: 2.5rem !important; }
  .mb-xl-40,
  .my-xl-40 {
    margin-bottom: 2.5rem !important; }
  .ml-xl-40,
  .mx-xl-40 {
    margin-left: 2.5rem !important; }
  .m-xl-50 {
    margin: 3.125rem !important; }
  .mt-xl-50,
  .my-xl-50 {
    margin-top: 3.125rem !important; }
  .mr-xl-50,
  .mx-xl-50 {
    margin-right: 3.125rem !important; }
  .mb-xl-50,
  .my-xl-50 {
    margin-bottom: 3.125rem !important; }
  .ml-xl-50,
  .mx-xl-50 {
    margin-left: 3.125rem !important; }
  .m-xl-60 {
    margin: 3.75rem !important; }
  .mt-xl-60,
  .my-xl-60 {
    margin-top: 3.75rem !important; }
  .mr-xl-60,
  .mx-xl-60 {
    margin-right: 3.75rem !important; }
  .mb-xl-60,
  .my-xl-60 {
    margin-bottom: 3.75rem !important; }
  .ml-xl-60,
  .mx-xl-60 {
    margin-left: 3.75rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .p-xl-10 {
    padding: 0.625rem !important; }
  .pt-xl-10,
  .py-xl-10 {
    padding-top: 0.625rem !important; }
  .pr-xl-10,
  .px-xl-10 {
    padding-right: 0.625rem !important; }
  .pb-xl-10,
  .py-xl-10 {
    padding-bottom: 0.625rem !important; }
  .pl-xl-10,
  .px-xl-10 {
    padding-left: 0.625rem !important; }
  .p-xl-15 {
    padding: 0.9375rem !important; }
  .pt-xl-15,
  .py-xl-15 {
    padding-top: 0.9375rem !important; }
  .pr-xl-15,
  .px-xl-15 {
    padding-right: 0.9375rem !important; }
  .pb-xl-15,
  .py-xl-15 {
    padding-bottom: 0.9375rem !important; }
  .pl-xl-15,
  .px-xl-15 {
    padding-left: 0.9375rem !important; }
  .p-xl-20 {
    padding: 1.25rem !important; }
  .pt-xl-20,
  .py-xl-20 {
    padding-top: 1.25rem !important; }
  .pr-xl-20,
  .px-xl-20 {
    padding-right: 1.25rem !important; }
  .pb-xl-20,
  .py-xl-20 {
    padding-bottom: 1.25rem !important; }
  .pl-xl-20,
  .px-xl-20 {
    padding-left: 1.25rem !important; }
  .p-xl-25 {
    padding: 1.5625rem !important; }
  .pt-xl-25,
  .py-xl-25 {
    padding-top: 1.5625rem !important; }
  .pr-xl-25,
  .px-xl-25 {
    padding-right: 1.5625rem !important; }
  .pb-xl-25,
  .py-xl-25 {
    padding-bottom: 1.5625rem !important; }
  .pl-xl-25,
  .px-xl-25 {
    padding-left: 1.5625rem !important; }
  .p-xl-30 {
    padding: 1.875rem !important; }
  .pt-xl-30,
  .py-xl-30 {
    padding-top: 1.875rem !important; }
  .pr-xl-30,
  .px-xl-30 {
    padding-right: 1.875rem !important; }
  .pb-xl-30,
  .py-xl-30 {
    padding-bottom: 1.875rem !important; }
  .pl-xl-30,
  .px-xl-30 {
    padding-left: 1.875rem !important; }
  .p-xl-40 {
    padding: 2.5rem !important; }
  .pt-xl-40,
  .py-xl-40 {
    padding-top: 2.5rem !important; }
  .pr-xl-40,
  .px-xl-40 {
    padding-right: 2.5rem !important; }
  .pb-xl-40,
  .py-xl-40 {
    padding-bottom: 2.5rem !important; }
  .pl-xl-40,
  .px-xl-40 {
    padding-left: 2.5rem !important; }
  .p-xl-50 {
    padding: 3.125rem !important; }
  .pt-xl-50,
  .py-xl-50 {
    padding-top: 3.125rem !important; }
  .pr-xl-50,
  .px-xl-50 {
    padding-right: 3.125rem !important; }
  .pb-xl-50,
  .py-xl-50 {
    padding-bottom: 3.125rem !important; }
  .pl-xl-50,
  .px-xl-50 {
    padding-left: 3.125rem !important; }
  .p-xl-60 {
    padding: 3.75rem !important; }
  .pt-xl-60,
  .py-xl-60 {
    padding-top: 3.75rem !important; }
  .pr-xl-60,
  .px-xl-60 {
    padding-right: 3.75rem !important; }
  .pb-xl-60,
  .py-xl-60 {
    padding-bottom: 3.75rem !important; }
  .pl-xl-60,
  .px-xl-60 {
    padding-left: 3.75rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.gutters-5 {
  margin-right: -0.3125rem;
  margin-left: -0.3125rem; }
  .gutters-5 > .col,
  .gutters-5 > [class*="col-"] {
    padding-right: 0.3125rem;
    padding-left: 0.3125rem; }

.gutters-10 {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }
  .gutters-10 > .col,
  .gutters-10 > [class*="col-"] {
    padding-right: 0.625rem;
    padding-left: 0.625rem; }

.gutters-20 {
  margin-right: -0.9375rem;
  margin-left: -0.9375rem; }
  .gutters-20 > .col,
  .gutters-20 > [class*="col-"] {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem; }

@media only screen and (max-width: 1440px) and (min-width: 1200px) {
  #box-menu .item-info-map {
    padding-right: 0px; } }

ul.box-list-project .expand-down {
  background: url(../../../images/expand-more-neg.svg) no-repeat;
  background-size: 30px 30px;
  width: 30px;
  height: 30px;
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  background-position: -2px; }
  ul.box-list-project .expand-down i {
    display: none; }

ul.box-list-project .expand-up {
  background: url(../../../images/expand-more-neg.svg) no-repeat;
  background-size: 30px 30px;
  width: 30px;
  height: 30px;
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
  background-position: 2px; }
  ul.box-list-project .expand-up i {
    display: none; }

.observatoire-page .list-social .item-social.item-mail a {
  border: none;
  background: url(../../../images/send.svg);
  background-size: 20px 20px;
  width: 20px;
  height: 20px;
  display: inline-block; }
  .observatoire-page .list-social .item-social.item-mail a i {
    display: none; }

.observatoire-page .list-social .item-social.item-print a {
  border: none;
  background: url(../../../images/print.svg);
  background-size: 20px 20px;
  width: 20px;
  height: 20px;
  display: inline-block; }
  .observatoire-page .list-social .item-social.item-print a i {
    display: none; }

.observatoire-page .list-social .item-social.item-share a {
  border: none;
  background: url(../../../images/share.svg);
  background-size: 20px 20px;
  width: 20px;
  height: 20px;
  display: inline-block; }
  .observatoire-page .list-social .item-social.item-share a i {
    display: none; }

.observatoire-page.sub-page .border-dotted.separateur-svg {
  overflow: hidden;
  width: 100%;
  border: none;
  margin-bottom: 0;
  margin-top: 15px; }
  .observatoire-page.sub-page .border-dotted.separateur-svg img {
    height: 3px;
    width: 2200px !important;
    max-width: none; }

.observatoire-page .box-news-related {
  border: none;
  padding-bottom: 0; }
  .observatoire-page .box-news-related .separateur-svg {
    padding-top: 30px; }

ul.box-list-project .box-project .view-more-project .full-text {
  border: none; }

.box-head .separateur-svg.border-white {
  margin: 0;
  padding: 0;
  position: relative;
  top: 0px; }
  .box-head .separateur-svg.border-white img {
    margin-left: 2px; }

#box-menu .item-socials .box-social .item {
  border: none;
  padding: 0; }
  #box-menu .item-socials .box-social .item .separateur-svg.border-white {
    padding-top: 20px;
    margin-bottom: 20px; }
  #box-menu .item-socials .box-social .item:last-child {
    padding-bottom: 15px; }
    #box-menu .item-socials .box-social .item:last-child .separateur-svg.border-white {
      display: none; }

#box-menu .item-info-map .excerpt {
  padding-bottom: 0;
  border-bottom: none;
  margin-bottom: 0; }
  #box-menu .item-info-map .excerpt .separateur-svg.border-white {
    padding-top: 20px;
    margin-bottom: 20px; }

#box-menu .item-note .date {
  border-bottom: none;
  padding: 0; }
  #box-menu .item-note .date .separateur-svg.border-white {
    padding-top: 5px;
    margin-bottom: 5px; }

.search-page {
  border: none;
  margin-bottom: 0; }

.search-page .border-bottom {
  padding-bottom: 0;
  border-bottom: none;
  margin-bottom: 0; }
  .search-page .border-bottom .separateur-svg {
    padding-top: 20px;
    margin-bottom: 30px; }

.box-news .box-view-more {
  bottom: 45px; }

#box-menu #datepicker .ui-datepicker-calendar thead {
  border: navajowhite;
  padding: 0; }

#box-menu #datepicker .ui-widget.ui-widget-content {
  position: relative; }
  #box-menu #datepicker .ui-widget.ui-widget-content .separateur-svg.border-white {
    padding: 0;
    margin: 0; }
  #box-menu #datepicker .ui-widget.ui-widget-content .separateur-svg.border-white.second {
    /* width: calc(100% - 20px);*/
    position: absolute;
    top: 75px;
    left: 0; }

.agenda-page .block-search .icon-calendar {
  background: url(../../../images/calendar.svg);
  width: 30px;
  height: 30px; }

.observatoire-page #block-search {
  padding-bottom: 0;
  margin-bottom: 0;
  border: none; }
  .observatoire-page #block-search > .separateur-svg {
    padding-top: 50px;
    margin-bottom: 50px; }

.observatoire-page .block-search .box-input-info {
  position: relative;
  border: none; }
  .observatoire-page .block-search .box-input-info .separateur-svg {
    width: auto;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    padding-top: 0; }
    .observatoire-page .block-search .box-input-info .separateur-svg img {
      width: 4px !important;
      height: 2200px !important;
      margin-top: -5px; }

.agenda-page .box-search .btn-search-form {
  padding-right: 30px;
  /*background: #000 url(../../../images/expand-more-neg.svg) no-repeat;*/
  background-size: 15px 15px;
  background-position: 94% 10px;
  border: 0px; }
  .agenda-page .box-search .btn-search-form i {
    display: none; }

.abecedaire-page .box-filter-anphabe {
  border: none;
  margin-bottom: 0; }

.abecedaire-page .box-result-filter {
  border-bottom: none;
  padding-bottom: 0;
  margin-bottom: 0; }
  .abecedaire-page .box-result-filter .separateur-svg {
    padding-top: 20px;
    margin-bottom: 20px; }

.abecedaire-page .input-search .btn-search-letter {
  background: url(../../../images/loupe.svg) no-repeat;
  background-size: 20px 20px;
  width: 30px;
  height: 30px;
  background-position: 3px 5px; }
  .abecedaire-page .input-search .btn-search-letter i {
    display: none; }

.search-page .input-search .btn-search-letter {
  background: url(../../../images/loupe.svg) no-repeat;
  background-size: 15px 15px;
  background-position: 5px 5px;
  width: 30px;
  height: 30px; }
  .search-page .input-search .btn-search-letter i {
    display: none; }

.observatoire-page .block-search {
  padding-bottom: 0;
  margin-bottom: 0;
  border: none; }
  .observatoire-page .block-search form .box-search > .separateur-svg {
    padding-top: 50px;
    margin-bottom: 50px; }

.observatoire-page .box-search .btn-search-form .icon-arrow-right {
  background: url(../../../images/expand-more-neg.svg) no-repeat; }

.search-page .btn-faq {
  background: #000 url(../../../images/expand-more-neg.svg) no-repeat;
  background-size: 12px 12px;
  background-position: 99%; }
  .search-page .btn-faq i {
    display: none; }

.box-magazines .box-view-more .view-more-news,
.observatoire-page .box-news-related .box-item-news .add-item-news {
  border: none;
  background: url(../../../images/contenus.svg);
  background-size: 30px 30px; }
  .box-magazines .box-view-more .view-more-news i,
  .observatoire-page .box-news-related .box-item-news .add-item-news i {
    display: none; }

.box-menu-right .btn-search {
  background: url(../../../images/loupe.svg);
  background-size: 30px 30px; }
  .box-menu-right .btn-search i {
    display: none; }

body #box-menu .box-menu-mobile .menu-btn-search {
  background: url(../../../images/loupe-neg.svg);
  background-size: 30px 30px; }
  body #box-menu .box-menu-mobile .menu-btn-search i {
    display: none; }

#box-menu .item-search .btn {
  background: url(../../../images/loupe-neg.svg) no-repeat;
  background-size: 25px 25px;
  width: 40px;
  height: 40px;
  background-position: 5px 10px; }
  #box-menu .item-search .btn i {
    display: none; }

.box-menu-right .btn-about-us {
  background: url(../../../images/follow.svg);
  background-size: 100px 30px;
  border: none;
  width: 100px;
  font-size: 0;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }
  .box-menu-right .btn-about-us:hover {
    zoom: 1.2; }

.box-menu-right .btn-information {
  background: url(../../../images/infos.svg);
  background-size: 30px 30px; }
  .box-menu-right .btn-information i {
    display: none; }

#box-menu .box-menu-mobile .menu-btn-information {
  background: url(../../../images/infos-neg.svg);
  background-size: 30px 30px;
  border: none; }
  #box-menu .box-menu-mobile .menu-btn-information i {
    display: none; }

.box-menu-right .btn-calendar {
  background: url(../../../images/agenda.svg);
  background-size: 30px 30px; }
  .box-menu-right .btn-calendar i {
    display: none; }

body #box-menu .box-menu-mobile .menu-btn-calendar {
  background: url(../../../images/agenda-neg.svg);
  background-size: 30px 30px; }
  body #box-menu .box-menu-mobile .menu-btn-calendar i {
    display: none; }

.box-menu-right .btn-menu {
  background: url(../../../images/burger.svg);
  background-size: 30px 30px;
  height: 30px; }
  .box-menu-right .btn-menu i {
    display: none; }

.box-menu-right .btn-close-menu.active {
  background: url(../../../images/burger-quit.svg);
  background-size: 20px 20px;
  width: 20px;
  height: 20px;
  margin-top: 5px; }
  .box-menu-right .btn-close-menu.active i {
    display: none; }

#box-menu .list-menu li.has-child span {
  background: url(../../../images/round-add-button-Menu.svg);
  background-size: 12px 12px;
  margin-top: 2px;
  width: 12px;
  height: 12px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }
  #box-menu .list-menu li.has-child span[aria-expanded='true'] {
    -webkit-transform: rotate(50deg);
    -ms-transform: rotate(50deg);
    transform: rotate(50deg);
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out; }
  #box-menu .list-menu li.has-child span i {
    display: none; }

.separateur-svg {
  overflow: hidden;
  padding-top: 10px;
  margin-bottom: 10px;
  width: 100%; }

.separateur-svg img {
  height: 3px;
  width: 2200px !important;
  max-width: none; }

.box-magazines .excerpts {
  padding-bottom: 0;
  margin-bottom: 0; }

.box-magazines .border-dot-bottom {
  border: 0; }

.box-magazines.magazines-2 .col-lg-3:first-child .separateur-svg {
  padding-top: 0; }

.box-magazines .item-house {
  padding-bottom: 0;
  margin-bottom: 0;
  border: none;
  margin-top: 0; }
  .box-magazines .item-house:first-child {
    margin-top: 10px; }

.border-magazine.dot-between {
  margin-top: 10px;
  margin-bottom: 10px; }

.box-magazines .list-magazine li a {
  padding: 0;
  border: none; }

.box-head {
  padding-bottom: 0;
  margin-bottom: 0;
  border: none; }
  .box-head .separateur-svg {
    margin-top: 15px;
    margin-bottom: 15px;
    display: inline-block; }

.box-news {
  padding-bottom: 0;
  margin-bottom: 0;
  border: none; }
  .box-news .separateur-svg {
    margin-top: 15px;
    margin-bottom: 50px;
    display: inline-block; }

#article-meme-sujet {
  margin-top: -30px; }

#article-manifestation-avenir {
  margin-top: 5px; }

.box-magazines.mg-default {
  padding-bottom: 0;
  margin-bottom: 0;
  border: none; }
  .box-magazines.mg-default > .separateur-svg {
    margin-top: 50px;
    margin-bottom: 50px;
    display: inline-block; }

.box-magazines .item-magazine {
  border: none; }

.box-magazines .col-lg-3 {
  position: relative;
  /* &:nth-child(4) {
        > .separateur-svg {
            display: none;
        }
    }*/ }
  .box-magazines .col-lg-3 > .separateur-svg {
    width: auto;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    padding-top: 0; }
    .box-magazines .col-lg-3 > .separateur-svg img {
      width: 4px !important;
      height: 2200px !important;
      margin-top: -5px; }

#box-menu .menu {
  padding-right: 20px; }

.observatoire-page.sub-page .box-title-map {
  padding-bottom: 0;
  margin-bottom: 0;
  border-bottom: none; }
  .observatoire-page.sub-page .box-title-map .separateur-svg {
    padding-top: 15px;
    margin-bottom: 15px; }

.box-magazines.magazines-2 {
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }

.mobile-only {
  display: none !important; }
  .mobile-only img {
    height: 3px !important;
    max-width: none !important; }

.mobile-only.separateur-svg {
  margin-top: 10px !important;
  margin-bottom: 30px !important; }

.mobile-load-more {
  background: #000 url(../../../images/ico-plus-white.png) no-repeat;
  background-position: center;
  height: 40px;
  width: 100%;
  margin-top: 20px;
  display: none; }

.box-magazines.mg-default .item-magazine {
  margin-bottom: 30px; }

@media only screen and (max-width: 1440px) and (min-width: 1200px) {
  #box-menu.active {
    right: calc(-30% + 75px); } }

@media screen and (max-width: 1199px) {
  .box-magazines.magazines-2 {
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start; }
    .box-magazines.magazines-2:before, .box-magazines.magazines-2::after {
      display: none; }
    .box-magazines.magazines-2 .col-lg-3 {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 50%;
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%; }
  #box-menu.active {
    left: 95%; }
  .box-head .col-lg-9 {
    width: 100%; }
  .box-head .col-lg-3 {
    padding-left: 0; }
  .box-magazines .col-lg-3:nth-child(2) > .separateur-svg {
    display: none; }
  .box-magazines .col-lg-3:nth-child(1),
  .box-magazines .col-lg-3:nth-child(2) {
    position: relative;
    margin-bottom: 100px; }
    .box-magazines .col-lg-3:nth-child(1)::after,
    .box-magazines .col-lg-3:nth-child(2)::after {
      content: '........................................................';
      padding-top: 10px;
      height: 1px;
      font-size: 13px;
      width: 100%;
      max-width: 100%;
      background-size: 3px;
      display: block;
      zoom: 1.5;
      letter-spacing: 1px;
      text-align: center;
      position: absolute;
      bottom: -15px; } }

@media (max-width: 1199px) and (min-width: 1025px) {
  .box-head {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
  ul.box-list-project li {
    margin-bottom: 0.3125rem; }
  .box-head .col-lg-9 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 75%;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .box-head .col-lg-3 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 25%;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .box-head .separateur-svg {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  ul.box-list-project li:last-child {
    margin-top: 0.3125rem;
    height: 42px; }
  ul.box-list-project .box-project .item-project {
    height: 137px; }
  ul.box-list-project .box-project,
  ul.box-list-project .box-project-missing {
    height: 137px; }
  ul.list-images-project li.active img {
    height: 610px;
    -o-object-fit: cover;
    object-fit: cover; } }

@media (max-width: 1024px) and (min-width: 481px) {
  ul.box-list-project li:first-child {
    position: absolute;
    bottom: 0;
    width: 49%;
    height: 40px;
    padding-top: 6px; }
    ul.box-list-project li:first-child .expand-up {
      display: inline-block !important;
      -webkit-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      transform: rotate(180deg); }
  ul.box-list-project li:last-child {
    width: 49%;
    float: right;
    height: 40px;
    padding-top: 6px; }
    ul.box-list-project li:last-child .expand-down {
      -webkit-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      transform: rotate(0deg); }
  ul.box-list-project li .box-project {
    display: none; }
    ul.box-list-project li .box-project.active {
      display: block; }
  ul.box-list-project li .item-project {
    width: 50%;
    left: 0; }
  ul.box-list-project li .view-more-project {
    width: 50%;
    float: right; }
  ul.box-list-project .box-project.active .item-project {
    width: 50%; } }

@media (max-width: 991px) {
  ul.box-list-project .expand-down {
    background-position: 7px; }
  .observatoire-page.sub-page .box-title-map {
    padding-top: 0;
    margin-top: 0;
    border: none; } }

@media (max-width: 768px) {
  #box-menu .box-menu-mobile {
    border-bottom: none; }
    #box-menu .box-menu-mobile li {
      padding-right: 20px; }
    #box-menu .box-menu-mobile ~ .separateur-svg.mobile-only {
      display: inline-block !important;
      margin-top: 0 !important;
      padding-top: 0; }
  body .container.active {
    right: 45%; }
  .box-magazines.magazines-2 {
    margin-bottom: 20px; } }

@media (max-width: 767px) {
  .box-magazines .col-lg-3:nth-child(1),
  .box-magazines .col-lg-3:nth-child(2) {
    margin-bottom: 60px; }
    .box-magazines .col-lg-3:nth-child(1)::after,
    .box-magazines .col-lg-3:nth-child(2)::after {
      content: '..............................................................................................';
      padding-top: 0; }
  .box-magazines.magazines-2 .col-lg-3:nth-child(1)::after,
  .box-magazines.magazines-2 .col-lg-3:nth-child(2)::after {
    content: '..............................................'; }
  .box-magazines.magazines-2 .col-lg-3 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .box-magazines.magazines-2 .col-lg-3:nth-child(1) > .separateur-svg,
  .box-magazines.magazines-2 .col-lg-3:nth-child(3) > .separateur-svg {
    display: block; }
  .observatoire-page .block-search .box-input-info .separateur-svg {
    display: none; }
  ul.box-list-project li:first-child .expand-up {
    display: inline-block !important; }
  ul.box-list-project .expand-up {
    background-position: -3px; }
  ul.box-list-project .expand-down {
    background-position: 3px; }
  .box-magazines .col-lg-3 > .separateur-svg {
    display: none; } }

@media (max-width: 640px) {
  .box-magazines .col-lg-3:nth-child(1)::after, .box-magazines .col-lg-3:nth-child(2)::after {
    content: '......................................................................'; }
  .box-magazines.magazines-2 .col-lg-3:nth-child(1)::after,
  .box-magazines.magazines-2 .col-lg-3:nth-child(2)::after {
    content: '..............................'; } }

@media (max-width: 480px) {
  .box-magazines .col-lg-3:nth-child(1), .box-magazines .col-lg-3:nth-child(2) {
    margin-bottom: 0; }
    .box-magazines .col-lg-3:nth-child(1):after, .box-magazines .col-lg-3:nth-child(2):after {
      display: none; }
  .row.box-magazines > .separateur-svg {
    display: none; }
  ul.box-list-project li ~ li {
    margin-top: 20px; }
  .mobile-only {
    display: block !important; }
  .box-news .item-news {
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0; }
  .box-news .separateur-svg,
  .box-magazines .separateur-svg {
    display: none; }
  .box-magazines .no-padding .item-magazine {
    border-bottom: none !important;
    padding-bottom: 0;
    margin-bottom: 0; }
  .item-magazine .separateur-svg.mobile-only {
    margin-top: 30px !important;
    margin-bottom: 40px !important; }
  ul.box-list-project .box-project .view-more-project .full-text {
    height: auto; }
  #box-menu.active {
    left: 100%; }
  body .container.active {
    right: 100%; }
  ul.box-list-project .set-bg-project.set-ton-color {
    display: none; }
  .mobile-hide {
    display: none; }
  .mobile-load-more {
    display: inline-block; }
  .row.box-magazines.magazines-2 .col-lg-3:last-child {
    margin-bottom: 40px; }
    .row.box-magazines.magazines-2 .col-lg-3:last-child .separateur-svg.mobile-only {
      display: none !important; }
  .box-magazines.magazines-2 .col-lg-3 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  .box-magazines.magazines-2 .col-lg-3:nth-child(1) > .separateur-svg,
  .box-magazines.magazines-2 .col-lg-3:nth-child(3) > .separateur-svg {
    display: none; } }

@media print {
  body {
    -webkit-print-color-adjust: exact !important; }
  .sf-display-none {
    display: none !important; }
  .border-dotted.separateur-svg,
  .breadcrumb-bar,
  .item-mail,
  .separateur-svg,
  .box-view-more,
  .box-news.result-search,
  .title-black.set-ton-color {
    display: none !important; }
  a[href]:after {
    content: none !important; }
  .title {
    font-size: 15px !important; } }
