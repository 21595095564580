// ====================
// Fonts Family
// Input: $type (font-family)
// Output: Font-family
// Ex: @include wp-font(font-body)
// ==> font-family: $font-body; font-weight: $font-weight-bold;
// ---
@mixin wp-font($type) {
    @if $type==font-body {
        font-family: $font-family;
        font-weight: $font-weight-body;
    }

    @else if $type==font-light {
        font-family: $font-family;
        font-weight: $font-weight-light;
    }

    @else if $type==font-semibold {
        font-family: $font-family;
        font-weight: $font-weight-semibold;
    }

    @else if $type==font-bold {
        font-family: $font-family;
        font-weight: $font-weight-bold;
    }
}

// ====================
// Fonts Size Rem
// Input: $type (Property css), $size (Value Px)
// Output: $type: convert to $rem
// ---
@function rem($size) {
    @return $size / $size-default+rem;
}

// ====================
// Read Icon List
// ----
@mixin icons($name, $width, $height, $type: true) {
    .#{$name} {
        width: $width;
        height: $height;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        display: inline-block;
    }

    @if $type {
        .#{$name} {
            background-image: url('../images/icons/#{$name}.svg');
        }
    }

    @else {
        .#{$name} {
            background-image: url('../images/icons/#{$name}.png');
        }
    }
}

// ====================
// Mixins
// ---
@mixin clearfix {

    &:before,
    &:after {
        display: table;
        content: " ";
    }

    &:after {
        clear: both;
    }
}

@mixin fontawesome($font-family: fa) {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;

    @if $font-family==fab {
        font-family: 'Font Awesome 5 Brands';
    }

    @else if $font-family==far {
        font-family: 'Font Awesome 5 Pro';
        font-weight: 400;
    }

    @else if $font-family==fal {
        font-family: 'Font Awesome 5 Pro';
        font-weight: 300;
    }

    @else if $font-family==fa {
        font-family: 'Font Awesome 5 Pro';
        font-weight: 900;
    }

    @else if $font-family==fas {
        font-family: 'Font Awesome 5 Pro';
        font-weight: 900;
    }
}

// ====================
// Mixins Form
// ---
@mixin form-default-label {
    label {
        display: block;
        margin-bottom: rem(5px);
    }
}

@mixin form-default-input {
    border: 1px solid rgba($title-color, .3);
    border-radius: rem(3px);
    background-color: $white-color;
    color: $title-color;

    &:focus {
        border: 1px solid rgba($title-color, .6);
        outline: none;
    }
}

// ====================
// Mixins Buttons
// ---
@mixin btn($bg: $link-color, $color: $white-color, $border-color: $link-color) {
    display: inline-block;
    border-radius: rem(5px);
    font-size: rem(16px);
    padding: rem(9px) rem(25px);
    border: 1px solid $border-color;
    background-color: $bg;
    color: $color;
    transition: 0.3s all;
    line-height: $line-height-default;

    &:hover,
    &:focus {
        background-color: darken($bg, 8%);
        color: $white-color;
        border-color: darken($border-color, 8%);
    }
}

// ====================
// Mixins Container bootstrap
// ---
@mixin make-container() {
    width: 100%;
    padding-right: ($grid-gutter-width / 2);
    padding-left: ($grid-gutter-width / 2);
    margin-right: auto;
    margin-left: auto;
}

@mixin container {
    @include make-container;

    @media (min-width: $desktop) {
        max-width: 1170px;
    }

    @media (min-width: $tablet-ht) and (max-width: $desktop - 1) {
        max-width: 970px;
    }

    @media (min-width: $tablet-vt) and (max-width: $tablet-ht - 1) {
        max-width: 750px;
    }
}

// ====================
// Mixins Flex
// ---
@mixin make-row() {
    display: flex;
    flex-wrap: wrap;
    margin-right: ($grid-gutter-width / -2);
    margin-left: ($grid-gutter-width / -2);
}

@mixin make-col-ready() {
    position: relative; // Prevent columns from becoming too narrow when at smaller grid tiers by
    // always setting `width: 100%;`. This works because we use `flex` values
    // later on to override this initial width.
    width: 100%;
    min-height: 1px; // Prevent collapsing
    padding-right: ($grid-gutter-width / 2);
    padding-left: ($grid-gutter-width / 2);
}

@mixin flex-col-xs {
    -webkit-flex-basis: 0;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
}

@mixin flex-width-col-xs($val) {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 $val;
    -ms-flex: 0 0 $val;
    flex: 0 0 $val;
    max-width: $val;
}

@mixin flex-width-auto {
    @include flex(1 1 auto);
}


// ====================
// Mixins Effect
// ---
@-webkit-keyframes fadeInFromNone {
    from {
        display: none;
        opacity: 0;
    }

    to {
        display: block;
        opacity: 1;
    }
}

@-moz-keyframes fadeInFromNone {
    from {
        display: none;
        opacity: 0;
    }

    to {
        display: block;
        opacity: 1;
    }
}

@-o-keyframes fadeInFromNone {
    from {
        display: none;
        opacity: 0;
    }

    to {
        display: block;
        opacity: 1;
    }
}

@keyframes fadeInFromNone {
    from {
        display: none;
        opacity: 0;
    }

    to {
        display: block;
        opacity: 1;
    }
}

@mixin animated {
    animation-fill-mode: both;
    animation-duration: 0.5s;
    animation-timing-function: cubic-bezier(0.0, 0.0, 1.0, 1.0);
}

@mixin show-fade($class-show) {
    display: none;

    &.#{$class-show} {
        display: block;
        @include animated;
        animation-name: fadeInFromNone;
    }
}

// ====================
// Page User basic
// ---
$page-class: page-user;
$setion-class: main;

@mixin page-user-basic {
    .#{$page-class} {
        .#{$setion-class} {
            background-color: #fafafa;
            padding-top: rem(40px);
            padding-bottom: rem(65px);
        }

        div.tabs {
            margin-bottom: rem(40px);
        }
    }

    form[id="user-login"],
    form[id="user-register-form"],
    form[id="user-pass"],
    .profile {
        border: 0 none;
        background-color: $white-color;
        @include container;
        padding: rem(50px) rem(100px);
        margin: 0 auto;
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
        border-radius: 3px;

        .form-item {
            margin-top: rem(15px);
            margin-bottom: rem(15px);
        }

        .form-actions {
            text-align: center;
            margin-top: rem(30px);
            margin-bottom: 0;
        }

        .description {
            margin-top: rem(5px);
            font-size: rem(13px);
            color: #999999;
        }

        .label {
            color: #999999;
            font-size: rem(14px);
            @include wp-font(font-body);
        }
    }

    @media (min-width: $tablet-vt) {

        form[id="user-login"],
        form[id="user-register-form"],
        form[id="user-pass"],
        .profile {
            width: rem(720px);
        }
    }

    @media (max-width: $tablet-vt - 1) {
        .#{$page-class} {
            .#{$setion-class} {
                padding-top: rem(20px);
                padding-bottom: rem(45px);
            }

            div.tabs {
                margin-bottom: rem(20px);
            }
        }

        form[id="user-login"],
        form[id="user-register-form"],
        form[id="user-pass"],
        .profile {
            margin-left: rem(15px);
            margin-right: rem(15px);
        }
    }

    @media (max-width: $mobile) {

        form[id="user-login"],
        form[id="user-register-form"],
        form[id="user-pass"],
        .profile {
            padding: rem(25px) rem(40px) rem(30px) rem(40px);
        }
    }
}

dl {
    a {
        color: $title-color;

        &:hover,
        &:focus {
            color: $link-color;
        }
    }

    dt {
        border-top: 1px dashed $border-color;
        padding-top: 10px;
        font-size: rem(14px);
        @include wp-font(font-bold);

        &:first-child {
            border-top: none;
            padding-top: 0;
        }

        +dt {
            margin-top: 10px;
        }

        &:empty {
            display: none;
        }
    }

    dd {
        font-size: rem(13px);
        @include wp-font(font-medium);
        margin-left: 0;
        padding-bottom: rem(10px);

        &:last-child {
            padding-bottom: 0;
        }
    }
}

/* Z-Index */
@function z($label) {
    @return map-get($zindex, $label);
}

// Media Screen
@mixin media($width) {
    @if $width=='xl' {
        @media (min-width: $desktop) {
            @content;
        }
    }

    @else if $width=='lg' {
        @media (min-width: $tablet-ht) and (max-width: $desktop - 1) {
            @content;
        }
    }

    @else if $width=='md' {
        @media (min-width: $tablet-vt) and (max-width: $tablet-ht - 1) {
            @content;
        }
    }

    @else if $width=='sm' {
        @media (max-width: $tablet-vt - 1) {
            @content;
        }
    }

    @else if $width=='mb' {
        @media (max-width: $mobile) {
            @content;
        }
    }

    @else if $width=='mbmd' {
        @media (max-width: $mobile-md) {
            @content;
        }
    }

    @else if $width=='mbsm' {
        @media (max-width: $mobile-sm) {
            @content;
        }
    }

    @else if $width=='mbxs' {
        @media (max-width: $mobile-xs) {
            @content;
        }
    }
}

// Minimum breakpoint width. Null for the smallest (first) breakpoint.
//
//    >> breakpoint-min(sm, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//    576px
@function breakpoint-min($name,
$breakpoints: $grid-breakpoints) {
    $min: map-get($breakpoints, $name);
    @return if($min !=0,
    $min,
    null);
}

@function breakpoint-infix($name,
$breakpoints: $grid-breakpoints) {
    @return if(breakpoint-min($name,
    $breakpoints)==null,
    "",
    "-#{$name}");
}

@mixin media-breakpoint-up($name,
$breakpoints: $grid-breakpoints) {
    $min: breakpoint-min($name, $breakpoints);

    @if $min {
        @media (min-width: $min) {
            @content;
        }
    }

    @else {
        @content;
    }
}

// Float
@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

        .float#{$infix}-left {
            float: left;
        }

        .float#{$infix}-right {
            float: right;
        }

        .float#{$infix}-none {
            float: none;
        }
    }
}

// Margin and Padding
@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

        @each $prop,
        $abbrev in (margin: m, padding: p) {

            @each $size,
            $length in $spacers {
                .#{$abbrev}#{$infix}-#{$size} {
                    #{$prop}: $length !important;
                }

                .#{$abbrev}t#{$infix}-#{$size},
                .#{$abbrev}y#{$infix}-#{$size} {
                    #{$prop}-top: $length !important;
                }

                .#{$abbrev}r#{$infix}-#{$size},
                .#{$abbrev}x#{$infix}-#{$size} {
                    #{$prop}-right: $length !important;
                }

                .#{$abbrev}b#{$infix}-#{$size},
                .#{$abbrev}y#{$infix}-#{$size} {
                    #{$prop}-bottom: $length !important;
                }

                .#{$abbrev}l#{$infix}-#{$size},
                .#{$abbrev}x#{$infix}-#{$size} {
                    #{$prop}-left: $length !important;
                }
            }
        }

        // Some special margin utils
        .m#{$infix}-auto {
            margin: auto !important;
        }

        .mt#{$infix}-auto,
        .my#{$infix}-auto {
            margin-top: auto !important;
        }

        .mr#{$infix}-auto,
        .mx#{$infix}-auto {
            margin-right: auto !important;
        }

        .mb#{$infix}-auto,
        .my#{$infix}-auto {
            margin-bottom: auto !important;
        }

        .ml#{$infix}-auto,
        .mx#{$infix}-auto {
            margin-left: auto !important;
        }
    }
}


@each $e,
$s in $list-gutters {
    .gutters-#{$e} {
        margin-right: rem(-$s);
        margin-left: rem(-$s);

        &>.col,
        &>[class*="col-"] {
            padding-right: rem($s);
            padding-left: rem($s);
        }
    }
}